import { Mesh, Vector3, InstancedInterleavedBuffer, InterleavedBufferAttribute } from 'three';
import { LineSegmentsGeometry } from './LineSegmentsGeometry.js';
import { LineMaterial } from './LineMaterial.js';

var Wireframe = function (geometry, material) {
  Mesh.call(this);
  this.type = 'Wireframe';
  this.geometry = geometry !== undefined ? geometry : new LineSegmentsGeometry();
  this.material = material !== undefined ? material : new LineMaterial({
    color: Math.random() * 0xffffff
  });
};

Wireframe.prototype = Object.assign(Object.create(Mesh.prototype), {
  constructor: Wireframe,
  isWireframe: true,
  computeLineDistances: function () {
    // for backwards-compatability, but could be a method of LineSegmentsGeometry...
    var start = new Vector3();
    var end = new Vector3();
    return function computeLineDistances() {
      var geometry = this.geometry;
      var instanceStart = geometry.attributes.instanceStart;
      var instanceEnd = geometry.attributes.instanceEnd;
      var lineDistances = new Float32Array(2 * instanceStart.data.count);

      for (let i = 0, j = 0, l = instanceStart.data.count; i < l; i++, j += 2) {
        start.fromBufferAttribute(instanceStart, i);
        end.fromBufferAttribute(instanceEnd, i);
        lineDistances[j] = j === 0 ? 0 : lineDistances[j - 1];
        lineDistances[j + 1] = lineDistances[j] + start.distanceTo(end);
      }

      var instanceDistanceBuffer = new InstancedInterleavedBuffer(lineDistances, 2, 1); // d0, d1

      geometry.setAttribute('instanceDistanceStart', new InterleavedBufferAttribute(instanceDistanceBuffer, 1, 0)); // d0

      geometry.setAttribute('instanceDistanceEnd', new InterleavedBufferAttribute(instanceDistanceBuffer, 1, 1)); // d1

      return this;
    };
  }()
});

export { Wireframe };
